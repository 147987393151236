<template>
  <b-overlay :show="isProcessing">
    <b-row>
      <b-col
        v-if="userBasicInfo"
        :md="'4'"
      >
        <b-card>
          <div>
            <h5 class="text-capitalize mb-75">
              {{ $t('Email') }}
            </h5>
            <p class="card-text">
              <a :href="`mailto:${userBasicInfo.email}`">{{ userBasicInfo.email }}</a>
            </p>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              {{ $t('Join Date') }}
            </h5>
            <p class="card-text">
              {{ FORMAT_DATE(userBasicInfo.createdAt) }}/{{ fromNow(userBasicInfo.createdAt) }}
            </p>
          </div>
          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              {{ $t('Projects') }}
            </h5>
            <p class="card-text">
              <b-badge
                v-for="project of userBasicInfo.project"
                :key="project.name"
                v-b-tooltip.hover
                variant="light-primary"
                class="mr-1"
                :title="project.description"
              >
                {{ project.name }}
              </b-badge>
            </p>
          </div>
        </b-card>

      </b-col>
      <b-col :md="'8'">
        <b-card>
          {{ $t('This Month\'s Attendance') }}
          <monthly-block
            :should-call-api="true"
            :month="Number(TODAY({format: 'MM'}))"
            :year="Number(TODAY({format: 'YYYY'}))"
            :user-id="userUuid"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-row>
          <b-col
            v-for="(info, counter) of information"
            :key="counter"
            class="d-flex align-items-stretch"
            :md=" fromModal ? '12' : '6' "
          >
            <b-card class="general-setting w-100">
              <div class="d-flex justify-content-between mb-1">
                <div>
                  <h4>{{ info.title }}</h4>
                </div>
                <div v-if="info.data.length && self">
                  <feather-icon
                    icon="EditIcon"
                    class="text-primary d-none edit-general-setting"
                    role="button"
                    size="20"
                    @click="$router.push(`general-settings#${info.fragment}`)"
                  />
                </div>
              </div>
              <b-row v-if="info.data.length">
                <b-col
                  v-for="(setting, index) of info.data"
                  :key="index"
                  class="transaction-item pt-2 "
                  :class="{ 'd-none' : !setting.value }"
                  cols="6"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        :variant="info.variant"
                      >
                        <feather-icon
                          size="18"
                          :icon="setting.icon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        {{ setting.name }}
                      </h6>
                      <small>{{ setting.value }}</small>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
              <b-alert
                v-else-if="self"
                show
                variant="warning"
                class="small cursor-pointer p-1"
              >
                <div
                  class="d-flex align-items-center"
                  @click="$router.push(`general-settings#${info.fragment}`)"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="mr-1"
                  />
                  <p>
                    {{ $t('alerts.configure-general-info') }}
                  </p>
                </div>
              </b-alert>
              <b-alert
                v-else
                show
                variant="warning"
                class="small text-center p-1"
              >
                {{ $t('No information found!') }}
              </b-alert>
            </b-card>

          </b-col>
          <b-col
            v-if="self"
            class="general-setting"
            md="6"
          >
            <b-card>
              <div class="d-flex justify-content-between mb-1">
                <h4>{{ $t('Banking Information') }}</h4>

                <p
                  v-if="bankingInfo.length && self"
                  class="cursor-pointer"
                  @click="$router.push('general-settings#banking')"
                >
                  {{ $t('View all') }}
                </p>
              </div>
              <div v-if="bankingInfo.length">
                <b-card
                  v-for="info in bankingInfo"
                  :key="info.information.uuid"
                  border-variant="secondary"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="60"
                        variant="light-success"
                      >
                        <i class="fa fa-university bank-icon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6>{{ info.information.alias }}</h6>
                      <p class="mb-0">
                        {{ info.information.bankAccount }}
                      </p>
                      <p class="mb-2">
                        {{ info.information.bankName }}
                      </p>
                    </b-media-body>
                  </b-media>
                  <b-card-footer class="text-right pb-0">
                    {{ getBillingType(info.information.__typename) }}
                  </b-card-footer>
                </b-card>
              </div>
              <b-alert
                v-else
                show
                variant="warning"
                class="small text-warning cursor-pointer p-1"
              >
                <div
                  class="d-flex align-items-center"
                  @click="$router.push(`general-settings#banking`)"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="mr-1"
                  />
                  <p>
                    {{ $t('alerts.configure-banking-info') }}
                  </p>
                </div>
              </b-alert>
            </b-card>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BCard, BMediaBody, BMedia, BMediaAside, BAvatar, BBadge, BOverlay, VBTooltip, BCardFooter, BAlert,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import MonthlyBlock from '@/views/Attendance/MonthBlocks.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BBadge,
    BOverlay,
    BAlert,
    BCardFooter,
    MonthlyBlock,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userBasicInfo: null,
      bankInfo: null,
      generalInfo: null,
      isProcessing: false,
      userData: getUserData(),
    }
  },
  computed: {
    information() {
      return [
        {
          title: 'General Information',
          data: this.generalInformation,
          variant: 'light-success',
          fragment: 'general',
        },
      ]
    },
    self() {
      return this.userData.uuid === this.userUuid
    },
    bankingInfo() {
      if (this.bankInfo) return this.bankInfo.filter((info, index) => index < 2)
      return []
    },
    fromModal() {
      return this.$parent.fromModal
    },
    generalInformation() {
      if (this.generalInfo) {
        const { information } = this.generalInfo
        return [
          {
            name: 'Alias',
            icon: 'UserPlusIcon',
            value: information.alias,
          },
          {
            name: 'Phone Number',
            icon: 'PhoneCallIcon',
            value: information.phoneNumber,
          },
          {
            name: 'Mobile Number 1',
            icon: 'PhoneCallIcon',
            value: information.mobileNumber,
          },
          {
            name: 'Mobile Number 2',
            icon: 'PhoneCallIcon',
            value: information.mobileNumber2,
          },
          {
            name: 'Country',
            icon: 'DribbbleIcon',
            value: information.country?.name,
          },
          {
            name: 'Street',
            icon: 'HomeIcon',
            value: information.streetAddress,
          },
          {
            name: 'District',
            icon: 'HomeIcon',
            value: information.district,
          },

        ]
      }
      return []
    },
  },
  created() {
    this.isProcessing = true
    useApollo.users.getUserProfileInfo(this.userUuid).then(response => {
      const userInfo = response.data.user
      this.userBasicInfo = {
        email: userInfo.email,
        createdAt: userInfo.createdAt,
        project: userInfo.projects?.data || [],
      }
      // eslint-disable-next-line no-underscore-dangle
      this.generalInfo = userInfo.extraInformations.data.find(data => data.information?.__typename === 'ContactInformation')
      // eslint-disable-next-line no-underscore-dangle
      this.bankInfo = userInfo.extraInformations.data.filter(data => ((data.information?.__typename === 'BillingInformation' || data.information?.__typename === 'AchBillingInformation' || data.information?.__typename === 'FxBillingInformation')))
    }).catch(error => {
      this.showErrorMessage(error)
    }).finally(() => {
      this.isProcessing = false
    })
  },
  methods: {
    getBillingType(type) {
      switch (type) {
        case 'AchBillingInformation':
          return 'ACH'
        case 'FxBillingInformation':
          return 'FX'
        default:
          return 'SWIFT'
      }
    },
  },
}
</script>
<style lang="scss">
  .general-setting:hover {
    .edit-general-setting {
      display: block  !important;
    }
  }
</style>
